import classnames from "classnames";
import * as React from "react";

import { useRequiredAuthContext } from "~/components/auth/common";
import { noop } from "~/utils/noop";

import { Button, ButtonLink } from "../Button";

interface Props {
  title?: string;
  description?: string;
  className?: string;
  resetFilters?: () => void;
}

const VoidPlaceholder: React.FC<Props> = ({
  title = "No usage yet",
  description = "Try filtering with a broader time frame, or check out our resources for getting students started with Pear Deck Tutor.",
  className,
  resetFilters
}: Props) => {
  const { selectedUmbrella } = useRequiredAuthContext();

  return (
    <div
      className={classnames(
        className,
        "flex flex-col gap-[16px] items-center justify-center text-center h-full border-t border-cream-300"
      )}
    >
      <div className="subtitle1">{title}</div>
      <div className="paragraph max-w-[434px]">{description}</div>
      <Button
        variant="secondary"
        className="mt-[16px] mb-[-8px]"
        action={resetFilters ? resetFilters : noop}
      >
        Reset all filters
      </Button>
      <ButtonLink
        href={
          selectedUmbrella.isCorporation
            ? "https://sites.google.com/goguardian.com/pdt-higher-ed-resources/partner-resources?pli=1"
            : "https://sites.google.com/goguardian.com/pdt-adoption-resources/adoption-resources"
        }
        variant="text"
        target="_blank"
        rel="noopener noreferrer"
        action={noop}
      >
        Help your students get started
      </ButtonLink>
    </div>
  );
};

export default VoidPlaceholder;
