import styled from "@emotion/styled";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

export interface Props {
  text?: string;
  className?: string;
}

const DEFAULT_TEXT = "Oops, something was wrong...";

const ErrorPlaceholder: React.FC<Props> = ({
  text = DEFAULT_TEXT,
  className
}: Props): JSX.Element => {
  return (
    <Wrapper className={className} variant="h5">
      {text}
    </Wrapper>
  );
};

export default ErrorPlaceholder;

const Wrapper = styled(Typography)`
  align-items: center;
  color: ${({ theme }): string => theme.palette.grey[400]};
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
