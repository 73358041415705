import { useObjectRef } from "@react-aria/utils";
import classnames from "classnames";
import React from "react";
import { AriaButtonProps, useButton } from "react-aria";

const base = classnames(
  "h-[40px] px-[32px] flex flex-grow-0 w-fit items-center rounded-[4px] buttonLabel whitespace-nowrap focus-visible:ring-[2px] focus-visible:ring-offset-[2px] !outline-none disabled:pointer-events-none"
);
export const ButtonVariants = {
  primary: classnames(
    base,
    "!text-white disabled:!text-gray-600",
    "bg-newGrowth-700 hover:bg-newGrowth-800 data-[active]:bg-newGrowth-900 disabled:bg-gray-200",
    "focus-visible:ring-newGrowth-700"
  ),
  primaryBlue: classnames(
    base,
    "!text-white disabled:!text-gray-600",
    "bg-blueSkies-700 hover:bg-blueSkies-800 data-[active]:bg-blueSkies-900 disabled:bg-gray-200",
    "focus-visible:ring-blueSkies-700"
  ),
  primaryRed: classnames(
    base,
    "!text-white disabled:!text-gray-600",
    "bg-redApple-700 hover:bg-redApple-800 data-[active]:bg-redApple-900 disabled:bg-gray-200",
    "focus-visible:ring-redApple-700"
  ),
  primaryYellow: classnames(
    base,
    "!text-white disabled:!text-gray-600",
    "bg-warning-700 hover:bg-goldStar-800 data-[active]:bg-warning-800 disabled:bg-gray-200",
    "focus-visible:ring-warning-700"
  ),
  primaryWhite: classnames(
    base,
    "!text-lunchboxBlue-800 disabled:!text-gray-600",
    "bg-white hover:bg-lunchboxBlue-100 data-[active]:bg-lunchboxBlue-200 disabled:bg-gray-200",
    "focus-visible:ring-white"
  ),
  secondary: classnames(
    base,
    "!text-newGrowth-700 hover:!text-newGrowth-800 data-[active]:!text-newGrowth-800 focus-visible:!text-newGrowth-700 disabled:!text-gray-600",
    "bg-white hover:bg-newGrowth-100 data-[active]:bg-newGrowth-200 disabled:bg-gray-50",
    "border-[2px] border-newGrowth-700 hover:border-newGrowth-800 data-[active]:border-newGrowth-800 focus-visible:border-newGrowth-700 disabled:border-gray-200",
    "focus-visible:ring-newGrowth-700"
  ),
  secondaryBlue: classnames(
    base,
    "!text-blueSkies-700 hover:!text-blueSkies-800 data-[active]:!text-blueSkies-800 focus-visible:!text-blueSkies-700 disabled:!text-gray-600",
    "bg-white hover:bg-blueSkies-100 data-[active]:bg-blueSkies-200 disabled:bg-gray-50",
    "border-[2px] border-blueSkies-700 hover:border-blueSkies-800 data-[active]:border-blueSkies-800 focus-visible:border-blueSkies-700 disabled:border-gray-200",
    "focus-visible:ring-blueSkies-700"
  ),
  secondaryRed: classnames(
    base,
    "!text-redApple-700 hover:!text-redApple-800 data-[active]:!text-redApple-800 focus-visible:!text-redApple-700 disabled:!text-gray-600",
    "bg-white  hover:bg-redApple-100 data-[active]:bg-redApple-200 disabled:bg-gray-50",
    "border-[2px] border-redApple-700 hover:border-redApple-800 data-[active]:border-redApple-800 focus-visible:border-redApple-700 disabled:border-gray-200",
    "focus-visible:ring-redApple-700"
  ),
  secondaryGray: classnames(
    base,
    "!text-gray-800 disabled:!text-gray-600",
    "bg-white hover:bg-gray-100 data-[active]:bg-gray-200 disabled:bg-gray-50",
    "border-[2px] border-gray-800 disabled:border-gray-200",
    "focus-visible:ring-gray-800"
  ),
  text: classnames(
    base,
    "underline underline-offset-[4px] !text-gray-800 disabled:!text-gray-600",
    "hover:bg-newGrowth-100 data-[active]:bg-newGrowth-200 disabled:bg-transparent",
    "focus-visible:ring-chalkboard-700"
  ),
  textOnBlue: classnames(
    base,
    "underline underline-offset-[4px] !text-gray-800 disabled:!text-gray-600",
    "hover:bg-blueSkies-100 data-[active]:bg-blueSkies-200 disabled:bg-transparent",
    "focus-visible:ring-blueSkies-700"
  ),
  file: classnames(
    base,
    "max-w-full",
    "!text-navy-900 disabled:!text-gray-600",
    "bg-lunchboxBlue-200 hover:bg-lunchboxBlue-300 data-[active]:bg-lunchboxBlue-400 disabled:bg-gray-100",
    "focus-visible:ring-chalkboard-700"
  ),
  fileError: classnames(
    base,
    "max-w-full",
    "!text-navy-900 disabled:!text-gray-600",
    "bg-redApple-200 disabled:bg-gray-100 hover:bg-redApple-300 focus:bg-redApple-300",
    "focus-visible:ring-primary-700"
  ),
  wrapper: classnames(
    "group inline-block flex-grow-0 w-fit focus-visible:ring-[2px] focus-visible:ring-offset-[2px] !outline-none cursor-pointer disabled:pointer-events-none",
    "focus-visible:ring-chalkboard-700"
  ),
  aiAssistant: classnames(
    "flex flex-grow-0 items-center h-[54px] px-[50px] w-fit rounded-[30px] buttonLabel whitespace-nowrap focus-visible:ring-[2px] focus-visible:ring-offset-[2px] !outline-none disabled:pointer-events-none",
    "!text-white disabled:!text-gray-600",
    "bg-newGrowth-700 hover:bg-newGrowth-800 data-[active]:bg-newGrowth-900 disabled:bg-gray-200",
    "focus-visible:ring-chalkboard-700"
  ),
  aiAssistantRed: classnames(
    "flex flex-grow-0 items-center h-[54px] px-[50px] w-fit rounded-[30px] buttonLabel whitespace-nowrap focus-visible:ring-[2px] focus-visible:ring-offset-[2px] !outline-none disabled:pointer-events-none",
    "!text-redApple-700 hover:!text-redApple-800 data-[active]:!text-redApple-800 focus-visible:!text-redApple-700 disabled:!text-gray-600",
    "bg-white  hover:bg-redApple-100 data-[active]:bg-redApple-200 disabled:bg-gray-50",
    "border-[2px] border-redApple-700 hover:border-redApple-800 data-[active]:border-redApple-800 focus-visible:border-redApple-700 disabled:border-gray-200",
    "focus-visible:ring-redApple-700"
  ),
  oAuth: classnames(
    "px-[28px] py-[14px] flex flex-grow-0 w-fit rounded-full whitespace-nowrap focus-visible:ring-[2px] focus-visible:ring-offset-[2px] disabled:pointer-events-none",
    "paragraph bg-white border-[2px] border-[#EAEAEA]",
    "focus-visible:ring-chalkboard-700"
  ),
  cta: classnames(
    "flex flex-grow-0 items-center h-[48px] px-[24px] w-fit rounded-[30px] buttonLabel whitespace-nowrap focus-visible:ring-[2px] focus-visible:ring-offset-[2px] !outline-none disabled:pointer-events-none",
    "!text-navy-900 disabled:!text-gray-600",
    "bg-highlighter-400 hover:bg-highlighter-500 data-[active]:bg-highlighter-600 disabled:bg-gray-200",
    "focus-visible:ring-highlighter-600"
  ),
  pearySecondary: classnames(
    base,
    "h-[48px] pl-[27px] !text-newGrowth-700 hover:!ext-newGrowth-800 bg-white data-[active]:!text-newGrowth-800 focus-visible:!text-newGrowth-700 disabled:text-gray-600",
    "border-[2px] border-newGrowth-700 hover:border-newGrowth-800 data-[active]:border-newGrowth-800 focus-visible:border-newGrowth-700 disabled:border-gray-200",
    "focus-visible:ring-chalkboard-700"
  )
};

export interface IButtonProps
  extends Exclude<AriaButtonProps, "isDisabled" & "onPress"> {
  variant: keyof typeof ButtonVariants;
  action: () => unknown;
  className?: string;
  disabled?: boolean;
  tabIndex?: number;
  keyDownAction?: (e: React.KeyboardEvent) => void;
  role?: string;
  "aria-selected"?: boolean;
}

export const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
  ({ variant, className, disabled, children, ...props }, ref) => {
    const buttonRef = useObjectRef(ref);
    const { buttonProps, isPressed } = useButton(
      {
        type: "button",
        isDisabled: disabled,
        onPress: props.action,
        onKeyDown: props.keyDownAction,
        ...props
      },
      buttonRef
    );

    return (
      <button
        {...buttonProps}
        tabIndex={props.tabIndex}
        aria-selected={props["aria-selected"]}
        className={classnames(ButtonVariants[variant], className)}
        data-active={isPressed || undefined}
        ref={buttonRef}
        role={props.role}
      >
        {children}
      </button>
    );
  }
);

export const ButtonLink: React.FC<IButtonProps & {
  isA?: boolean;
  href: string;
  action?: () => void;
} & React.HTMLAttributes<HTMLAnchorElement>> = ({
  href,
  variant,
  action,
  className,
  children,
  ...props
}) => {
  return (
    <a
      draggable={false}
      href={href}
      className={classnames(
        ["primary"].includes(variant) && "!text-white",
        ButtonVariants[variant],
        className
      )}
      {...props}
      onClick={action}
    >
      {children}
    </a>
  );
};
